












































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import moment from "moment-jalaali";
@Component
export default class component_name extends Vue {
  @Prop()
  item!: any;
  JalaaliDate(date: any) {
    return moment(date).format("jYYYY/jMM/jDD");
  }
  deleteCoupon() {
    this.$emit("delete", this.item.id);
  }
}
