





















































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import { CouponApi } from "@/networks/CouponApi";
import MobileList from "@/views/components/pages/Coupon/CouponCard.vue";
import moment from "moment-jalaali";
@Component({
  components: {
    MobileList
  }
})
export default class ListEvent extends Vue {
  private search = "";
  private data: any = [];
  private items: any = [];
  private form: any = {};
  private roles = localStorage.getItem("roles") || [];
  private page = 1;
  mounted() {
    if (window.innerWidth < 600) {
      document.addEventListener("scroll", this.HandleScrollMobile);
    }
    this.coupons();
  }
  private timeout: any = null;
  HandleScrollMobile(e: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (
        e.target.scrollingElement.clientHeight +
          e.target.scrollingElement.scrollTop >=
        e.target.scrollingElement.scrollHeight - 100
      ) {
        this.page++;
      }
    }, 300);
  }

  JalaaliDate(date: any) {
    return moment(date).format("jYYYY/jMM/jDD");
  }
  async deleteCoupon(id: string) {
    const confirm = window.confirm("آیا مطمئن به حذف کوپن هستید؟");
    if (!confirm) return;
    try {
      const res: AxiosResponse = await new CouponApi().deleteCoupon(id);
      store.commit("showError", {
        message: res.data.message || "کوپن با موفقیت حذف شد",
        color: "success"
      });
      this.coupons();
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "error"
      });
    }
  }
  public onFormChanged() {
    this.$router.push({ query: { search: this.search } });
    this.fetchEntriesDebounced();
  }
  private _timerId = 0;
  fetchEntriesDebounced(): void {
    // cancel pending call
    clearTimeout(this._timerId);

    // delay new call 500ms
    this._timerId = window.setTimeout(() => {
      this.page = 1;
      if (window.innerWidth < 600) {
        document.addEventListener("scroll", this.HandleScrollMobile);
      }
      this.coupons();
    }, 700);
  }
  public async coupons(): Promise<void> {
    try {
      if (window.innerWidth < 600 && this.page == 1) {
        this.items = [];
      }
      const res: AxiosResponse = await new CouponApi().list({
        q: this.search,
        page: this.page
      });
      if (window.innerWidth < 600) {
        this.items = [...this.items, ...res.data.data.data];
        if (this.page == res.data.data.last_page) {
          document.removeEventListener("scroll", this.HandleScrollMobile);
        }
      }
      this.data = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("page")
  public onPageChanged() {
    this.coupons();
  }
}
